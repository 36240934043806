//----------------モーダル（Bootstrap）----------------
$(function(){
  var myModal = document.getElementById('contactRequest')
    var myInput = document.getElementById('message')
    if (myModal) {
      myModal.addEventListener('shown.bs.modal', function () {
        myInput.focus()
      })
    }
  });


$('.product_form').validate({
  rules: {
    "product_form[name]": { required: true },
    "product_form[category_id]": { required: true },
    "product_form[service_kind_id]": { required: true },
    "product_form[description]": { required: true },
    "product_form[want_sell_reason]": { required: true },
    "product_form[user_num]": { required: true },
    "product_form[founding_years]": { required: true },
    "product_form[founding_months]": { required: true },
    "product_form[want_sell_amount]": { required: true, range:[100, 300000000] },
    "product_form[want_sell_amount_max]": { range:[100, 300000000] },
    "product_form[want_sell_season_select]": { required: true },
    "product_form[recent_price_select]":{required :true},
    "product_form[sales_form_ids][]": { required: true, minlength: 1 },
    "product_form[want_mediation]": { required: true },
    "product_form[participation]": { required: true }
  },
  messages: {
    "product_form[name]": { required: "※ひとことプロダクト紹介を入力してください", },
    "product_form[category_id]": { required: "※選択してください", },
    "product_form[service_kind_id]": { required: "※選択してください", },
    "product_form[description]": { required: "※プロダクトの説明・強みを入力してください", },
    "product_form[want_sell_reason]": { required: "※売却理由を入力してください", },
    "product_form[user_num]": { required: "※選択してください", },
    "product_form[founding_years]": { required: "※年を選択してください", },
    "product_form[founding_months]": { required: "※月を選択してください", },
    "product_form[want_sell_amount]": { 
      required: "※最低販売価格を入力してください", 
      range: "※最低販売価格は100円～3億円の範囲で入力してください。",
    },
    "product_form[want_sell_amount_max]": {
      range: "※最高販売価格は100円～3億円の範囲で入力してください。",
    },
    "product_form[want_sell_season_select]": { required: "※売却希望時期を入力してください", },
    "product_form[recent_price_select]": { required: "※選択してください", },
    "product_form[sales_form_ids][]": { required: "※選択してください", },
    "product_form[want_mediation]": { required: "※選択してください", },
    "product_form[participation]": { required: "※選択してください", }
  },
  errorPlacement: function(error, element) 
  {
    if (element.is(":checkbox") || element.is(":radio") || element.is("select#product_form_founding_years") || element.is("select#product_form_founding_months")) 
    {
      if(element.is("select#product_form_founding_years")) {
        error.appendTo('.foundingYears');
      } else if(element.is("select#product_form_founding_months")) {
        error.appendTo('.foundingMonths');
      } else {
        error.appendTo(element.parent().closest('.flexWrap').next('.errorText'));
      }
    }
    else if(element.is("input#product_form_want_sell_amount"))
    {
      error.appendTo('.sell');
    } else if(element.is("input#product_form_want_sell_amount_max"))
    {
      error.appendTo('.sellMax');
    } else
    {
      error.appendTo(element.next('.errorText'));
    }
  }
});

$("body").delegate(".likeProduct", "click", function(e) {
  e.preventDefault()
  var id = $(this).data('id')
  if ($(".likeProduct[data-id="+ id +"]").hasClass("-like")) {
    $(".likeProduct[data-id="+ id +"]").removeClass("-like")
    $(".likeProduct[data-id="+ id +"]").addClass("-dislike")
    $(".likeProduct[data-id="+ id +"] p").html("お気に入り登録")
  } else {
    $(".likeProduct[data-id="+ id +"]").removeClass("-dislike")
    $(".likeProduct[data-id="+ id +"]").addClass("-like")
    $(".likeProduct[data-id="+ id +"] p").html("お気に入り済み")
  }
  $.ajax({
    type: 'POST',
    beforeSend: function(xhr) {xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'))},
    url: '/products/' + id + '/likes?page=index',
    error: function() {
      if ($(".likeProduct[data-id="+ id +"]").hasClass("-like")) {
        $(".likeProduct[data-id="+ id +"]").removeClass("-like")
        $(".likeProduct[data-id="+ id +"]").addClass("-dislike")
        $(".likeProduct[data-id="+ id +"] p").html("お気に入り登録")
        $(".productListLike__item[data-id="+ id +"]").remove()
      } else {
        $(".likeProduct[data-id="+ id +"]").removeClass("-dislike")
        $(".likeProduct[data-id="+ id +"]").addClass("-like")
        $(".likeProduct[data-id="+ id +"] p").html("お気に入り済み")
      }
    }
  });
})

var isFormValid = true;
$("#product_form_want_sell_amount, #product_form_want_sell_amount_max").on("keyup", function() {
  if ($("#product_form_want_sell_amount_max").val()) {
    if (parseInt($("#product_form_want_sell_amount").val()) > parseInt($("#product_form_want_sell_amount_max").val())) {
      $(".sellMinMax").text('※最低販売価格小なりイコール最高販売価格の金額を入力してください。')
      isFormValid = false
      return
    } else {
      $(".sellMinMax").text('')
      isFormValid = true
    }
  } else {
    $(".sellMinMax").text('')
    isFormValid = true
  }
})
$(".product_form").submit(function (e) {
  if(!isFormValid){
    e.preventDefault();
    $("#product_form_want_sell_amount_max").focus()
  }
});
